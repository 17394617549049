<div class="phone-hidden">
    <div #collapse="ngbCollapse" [(ngbCollapse)]="filteringCollapsed">
        <ng-container [ngTemplateOutlet]="filterSection"></ng-container>
    </div>
</div>

<div class="not-phone-hidden">
    <app-drawer #commentDrawer="drawer" [isOpen]="!filteringCollapsed" [style.--drawer-width]="'300px'" [options]="{topOffset: 56}" (drawerClosed)="filteringCollapsed = !filteringCollapsed">
        <div header>
            <h2 style="margin-top: 0.5rem">Book Settings
                <button type="button" class="btn-close" aria-label="Close" (click)="commentDrawer.close()"></button>
            </h2>
        </div>
        <div body class="drawer-body">
            <ng-container [ngTemplateOutlet]="filterSection"></ng-container>
        </div>
    </app-drawer>
</div>

<ng-template #filterSection>
    <ng-template #globalFilterTooltip>This is library agnostic</ng-template>
    <div class="filter-section mx-auto pb-3">
        <div class="row justify-content-center g-0">
            <div class="col-md-2 me-3" *ngIf="!filterSettings.formatDisabled">
                <div class="mb-3">
                    <label for="format" class="form-label">Format</label>&nbsp;<i class="fa fa-info-circle" aria-hidden="true" placement="right" [ngbTooltip]="globalFilterTooltip" role="button" tabindex="0"></i>
                    <span class="visually-hidden" id="filter-global-format-help"><ng-container [ngTemplateOutlet]="globalFilterTooltip"></ng-container></span>
                    <app-typeahead (selectedData)="updateFormatFilters($event)" [settings]="formatSettings" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.title}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.title}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3"*ngIf="!filterSettings.libraryDisabled">
                <div class="mb-3">
                    <label for="libraries" class="form-label">Libraries</label>
                    <app-typeahead (selectedData)="updateLibraryFilters($event)" [settings]="librarySettings" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="!filterSettings.collectionDisabled">
                <div class="mb-3">
                    <label for="collections" class="form-label">Collections</label>&nbsp;<i class="fa fa-info-circle" aria-hidden="true" placement="right" [ngbTooltip]="globalFilterTooltip" role="button" tabindex="0"></i>
                    <span class="visually-hidden" id="filter-global-collections-help"><ng-container [ngTemplateOutlet]="globalFilterTooltip"></ng-container></span>
                    <app-typeahead (selectedData)="updateCollectionFilters($event)" [settings]="collectionSettings" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.title}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.title}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="!filterSettings.genresDisabled">
                <div class="mb-3">
                    <label for="genres" class="form-label">Genres</label>
                    <app-typeahead (selectedData)="updateGenreFilters($event)" [settings]="genreSettings" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.title}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.title}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="!filterSettings.tagsDisabled">
                <div class="mb-3">
                    <label for="tags" class="form-label">Tags</label>
                    <app-typeahead (selectedData)="updateTagFilters($event)" [settings]="tagsSettings" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.title}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.title}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>
        </div>
        <div class="row justify-content-center g-0">
            <!-- The People row -->
            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.CoverArtist)">
                <div class="mb-3">
                    <label for="cover-artist" class="form-label">Cover Artists</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.CoverArtist)" [settings]="getPersonsSettings(PersonRole.CoverArtist)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.Writer)">
                <div class="mb-3">
                    <label for="writers" class="form-label">Writers</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.Writer)" [settings]="getPersonsSettings(PersonRole.Writer)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.Publisher)">
                <div class="mb-3">
                    <label for="publisher" class="form-label">Publisher</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.Publisher)" [settings]="getPersonsSettings(PersonRole.Publisher)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.Penciller)">
                <div class="mb-3">
                    <label for="penciller" class="form-label">Penciller</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.Penciller)" [settings]="getPersonsSettings(PersonRole.Penciller)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.Letterer)">
                <div class="mb-3">
                    <label for="letterer" class="form-label">Letterer</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.Letterer)" [settings]="getPersonsSettings(PersonRole.Letterer)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.Inker)">
                <div class="mb-3">
                    <label for="inker" class="form-label">Inker</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.Inker)" [settings]="getPersonsSettings(PersonRole.Inker)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.Editor)">
                <div class="mb-3">
                    <label for="editor" class="form-label">Editor</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.Editor)" [settings]="getPersonsSettings(PersonRole.Editor)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.Colorist)">
                <div class="mb-3">
                    <label for="colorist" class="form-label">Colorist</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.Colorist)" [settings]="getPersonsSettings(PersonRole.Colorist)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.Character)">
                <div class="mb-3">
                    <label for="character" class="form-label">Character</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.Character)" [settings]="getPersonsSettings(PersonRole.Character)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>

            <div class="col-md-2 me-3" *ngIf="peopleSettings.hasOwnProperty(PersonRole.Translator)">
                <div class="mb-3">
                    <label for="translators" class="form-label">Translators</label>
                    <app-typeahead (selectedData)="updatePersonFilters($event, PersonRole.Translator)" [settings]="getPersonsSettings(PersonRole.Translator)" [reset]="resetTypeaheads">
                        <ng-template #badgeItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                        <ng-template #optionItem let-item let-position="idx">
                            {{item.name}}
                        </ng-template>
                    </app-typeahead>
                </div>
            </div>
        </div>
        <div class="row justify-content-center g-0">
            <div class="col-md-2 me-3" *ngIf="!filterSettings.readProgressDisabled">
                <label class="form-label">Read Progress</label>
                <form [formGroup]="readProgressGroup">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="notread" formControlName="notRead">
                        <label class="form-check-label" for="notread">Unread</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="inprogress" formControlName="inProgress">
                        <label class="form-check-label" for="inprogress">In Progress</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="read" formControlName="read">
                        <label class="form-check-label" for="read">Read</label>
                      </div>
                </form>
            </div>

            <div class="col-md-2 me-3" *ngIf="!filterSettings.ratingDisabled">
                <label for="ratings" class="form-label">Rating</label>
                <form class="form-inline">
                    <ngb-rating class="rating-star" [(rate)]="filter.rating" (rateChange)="updateRating($event)" [resettable]="true">
                        <ng-template let-fill="fill" let-index="index">
                            <span class="star" [class.filled]="(index >= (filter.rating - 1)) && filter.rating > 0" [ngbTooltip]="(index + 1) + ' and up'">&#9733;</span>
                          </ng-template>
                    </ngb-rating>
                </form>
            </div>

            <div class="col-md-2 me-3" *ngIf="!filterSettings.ageRatingDisabled">
                <label for="age-rating" class="form-label">Age Rating</label>
                <app-typeahead (selectedData)="updateAgeRating($event)" [settings]="ageRatingSettings" [reset]="resetTypeaheads">
                    <ng-template #badgeItem let-item let-position="idx">
                        {{item.title}}
                    </ng-template>
                    <ng-template #optionItem let-item let-position="idx">
                        {{item.title}}
                    </ng-template>
                </app-typeahead>
            </div>

            <div class="col-md-2 me-3" *ngIf="!filterSettings.languageDisabled">
                <label for="languages" class="form-label">Language</label>
                <app-typeahead (selectedData)="updateLanguageRating($event)" [settings]="languageSettings" [reset]="resetTypeaheads">
                    <ng-template #badgeItem let-item let-position="idx">
                        {{item.title}}
                    </ng-template>
                    <ng-template #optionItem let-item let-position="idx">
                        {{item.title}}
                    </ng-template>
                </app-typeahead>
            </div>

            <div class="col-md-2 me-3" *ngIf="!filterSettings.publicationStatusDisabled">
                <label for="publication-status" class="form-label">Publication Status</label>
                <app-typeahead (selectedData)="updatePublicationStatus($event)" [settings]="publicationStatusSettings" [reset]="resetTypeaheads">
                    <ng-template #badgeItem let-item let-position="idx">
                        {{item.title}}
                    </ng-template>
                    <ng-template #optionItem let-item let-position="idx">
                        {{item.title}}
                    </ng-template>
                </app-typeahead>
            </div>
            <div class="col-md-2 me-3"></div>
        </div>
        <div class="row justify-content-center g-0">
            <div class="col-md-2 me-3">
                <form [formGroup]="seriesNameGroup">
                    <div class="mb-3">
                        <label for="series-name" class="form-label">Series Name</label>&nbsp;<i class="fa fa-info-circle" aria-hidden="true" placement="right" [ngbTooltip]="seriesNameFilterTooltip" role="button" tabindex="0"></i>
                        <span class="visually-hidden" id="filter-series-name-help"><ng-container [ngTemplateOutlet]="seriesNameFilterTooltip"></ng-container></span>
                        <ng-template #seriesNameFilterTooltip>Series name will filter against Name, Sort Name, or Localized Name</ng-template>
                        <input type="text" id="series-name" formControlName="seriesNameQuery" class="form-control" aria-describedby="filter-series-name-help">
                    </div>
                </form>
            </div>
            <div class="col-md-2 me-3" *ngIf="!filterSettings.sortDisabled">
                <form [formGroup]="sortGroup">
                    <div class="mb-3">
                        <label for="sort-options" class="form-label">Sort By</label>
                        <button class="btn btn-sm btn-secondary-outline" (click)="updateSortOrder()" style="height: 25px; padding-bottom: 0px;">
                            <i class="fa fa-arrow-up" title="Ascending" *ngIf="isAscendingSort; else descSort"></i>
                            <ng-template #descSort>
                                <i class="fa fa-arrow-down" title="Descending"></i>
                            </ng-template>
                        </button>
                        <select id="sort-options" class="form-select" formControlName="sortField" style="height: 38px;">
                            <option [value]="SortField.SortName">Sort Name</option>
                            <option [value]="SortField.Created">Created</option>
                            <option [value]="SortField.LastModified">Last Modified</option> 
                        </select>
                    </div>
                </form>
            </div>  
            <div class="col-md-2 me-3" *ngIf="filterSettings.sortDisabled"></div>
            <div class="col-md-2 me-3"></div>
            <div class="col-md-2 me-3 mt-4">
                <button class="btn btn-secondary col-12" (click)="clear()">Clear</button>
            </div>
            <div class="col-md-2 me-3 mt-4">
                <button class="btn btn-primary col-12" (click)="apply()">Apply</button>
            </div>
        </div>
    </div>
</ng-template>