<div *ngIf="series !== undefined">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            {{series.name}} Review</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()">
        
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="reviewGroup">
            <div class="row g-0">
                <label for="rating" class="form-label">Rating</label>
                <div>
                    <ngb-rating style="margin-top: 2px; font-size: 1.5rem;" formControlName="rating"></ngb-rating>
                    <button class="btn btn-icon ms-2" (click)="clearRating()" title="clear"><i aria-hidden="true" class="fa fa-ban"></i></button>
                </div>
            </div>

            <div class="row g-0">
                <label for="review" class="form-label">Review</label>
                <textarea id="review" class="form-control" formControlName="review" rows="3"></textarea>
            </div>
        </form>
        
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="close()">Close</button>
        <button type="submit" class="btn btn-primary" (click)="save()">Save</button>
    </div>
</div>


