<div class="carousel-container" *ngIf="items.length > 0">
    <div>
        <h3 style="display: inline-block;"><a href="javascript:void(0)" (click)="sectionClicked($event)" class="section-title" [ngClass]="{'non-selectable': !clickableTitle}">{{title}}</a></h3>
        <div class="float-end">
            <button class="btn btn-icon" [disabled]="isBeginning" (click)="prevPage()"><i class="fa fa-angle-left" aria-hidden="true"></i><span class="visually-hidden">Previous Items</span></button>
            <button class="btn btn-icon" [disabled]="isEnd" (click)="nextPage()"><i class="fa fa-angle-right" aria-hidden="true"></i><span class="visually-hidden">Next Items</span></button>
        </div>
    </div>
    <div>
        <swiper
            [slidesPerView]="'auto'"
            (init)="onSwiper($event)"
            [freeMode]="true">
            <ng-template *ngFor="let item of items; index as i;" swiperSlide>
                <ng-container [ngTemplateOutlet]="carouselItemTemplate" [ngTemplateOutletContext]="{ $implicit: item, idx: i }"></ng-container>
            </ng-template>
        </swiper>
    </div>
</div>