<app-side-nav-companion-bar>
  <h2 title>
    <span *ngIf="actions.length > 0">
      <app-card-actionables (actionHandler)="performAction($event)" [actions]="actions" [labelBy]="readingList.title"></app-card-actionables>
    </span>
    {{readingList.title}}&nbsp;<span *ngIf="readingList?.promoted">(<i class="fa fa-angle-double-up" aria-hidden="true"></i>)</span>&nbsp;
    <span class="badge bg-primary rounded-pill" attr.aria-label="{{items.length}} total items">{{items.length}}</span>
  </h2>
</app-side-nav-companion-bar>
<div class="container-fluid mt-2" *ngIf="readingList">
  <div class="mb-3">
    <!-- Action row-->
    <div class="row g-0">
      <div class="col-auto me-2">
        <button class="btn btn-primary" title="Read" (click)="read()">
            <span>
                <i class="fa fa-book-open" aria-hidden="true"></i>
                <span class="read-btn--text">&nbsp;Read</span>
            </span>
        </button>
      </div>
      <div class="col-auto">
          <button class="btn btn-secondary" (click)="removeRead()" [disabled]="readingList?.promoted && !this.isAdmin">
              <span>
                  <i class="fa fa-check"></i>
              </span>
              <span class="read-btn--text">&nbsp;Remove Read</span>
          </button>
      </div>
      <div class="col-auto ms-2 mt-2" *ngIf="!(readingList?.promoted && !this.isAdmin)">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="accessibilit-mode" [value]="accessibilityMode" (change)="accessibilityMode = !accessibilityMode">
          <label class="form-check-label" for="accessibilit-mode">Order Numbers</label>
        </div>
      </div>
    </div>
    <!-- Summary row-->
    <div class="row g-0 mt-2">
      <app-read-more [text]="readingListSummary" [maxLength]="250"></app-read-more>
    </div>
    </div>

    <div *ngIf="items.length === 0">
      No chapters added
    </div>    

    <app-dragable-ordered-list [items]="items" (orderUpdated)="orderUpdated($event)" (itemRemove)="itemRemoved($event)" [accessibilityMode]="accessibilityMode">
        <ng-template #draggableItem let-item let-position="idx">
            <div class="d-flex" style="width: 100%;">
                <app-image width="74px" class="img-top me-3" [imageUrl]="imageService.getChapterCoverImage(item.chapterId)"></app-image>
                <div class="flex-grow-1">
                  <h5 class="mt-0 mb-1" id="item.id--{{position}}">{{formatTitle(item)}}&nbsp;
                      <span class="badge bg-primary rounded-pill">
                      <span *ngIf="item.pagesRead > 0 && item.pagesRead < item.pagesTotal">{{item.pagesRead}} / {{item.pagesTotal}}</span>
                      <span *ngIf="item.pagesRead === 0">UNREAD</span>
                      <span *ngIf="item.pagesRead === item.pagesTotal">READ</span>
                    </span>
                  </h5>
                  <i class="fa {{utilityService.mangaFormatIcon(item.seriesFormat)}}" aria-hidden="true" *ngIf="item.seriesFormat != MangaFormat.UNKNOWN" title="{{utilityService.mangaFormat(item.seriesFormat)}}"></i>
                  <span class="visually-hidden">{{utilityService.mangaFormat(item.seriesFormat)}}</span>&nbsp;

                  <a href="/library/{{item.libraryId}}/series/{{item.seriesId}}">{{item.seriesName}}</a>
                  <span *ngIf="item.promoted">
                    <i class="fa fa-angle-double-up" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
        </ng-template> 
    </app-dragable-ordered-list>
</div>