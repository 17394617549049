<app-nav-header></app-nav-header>
<div [ngClass]="{'closed' : !(navService?.sideNavCollapsed$ | async), 'content-wrapper': navService.sideNavVisibility$ | async}">
    <a id="content"></a>
    <app-side-nav *ngIf="navService.sideNavVisibility$ | async"></app-side-nav>
    <div class="container-fluid">
        <div style="padding-top: 10px; padding-bottom: 65px;" *ngIf="navService.sideNavVisibility$ | async else noSideNav">
            <div class="companion-bar" [ngClass]="{'companion-bar-content': (navService?.sideNavCollapsed$ | async)}">
                <router-outlet></router-outlet>
            </div>
        </div>
        <ng-template #noSideNav>
            <router-outlet></router-outlet>
        </ng-template>
    </div>
    
</div>
