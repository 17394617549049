<div *ngIf="data !== undefined">
    <div class="modal-header">
        <h4 *ngIf="libraryType !== LibraryType.Comic else comicHeader" class="modal-title" id="modal-basic-title">
            {{parentName}} - {{data.number != 0 ? (isChapter ? 'Chapter ' : 'Volume ') + data.number : 'Special'}} Details</h4>
        <ng-template #comicHeader><h4 class="modal-title" id="modal-basic-title">
            {{parentName}} - {{data.number != 0 ? (isChapter ? 'Issue #' : 'Volume ') + data.number : 'Special'}} Details</h4>
        </ng-template>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()">
        
        </button>
    </div>
    <div class="modal-body scrollable-modal {{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? '' : 'd-flex'}}">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="{{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? 'horizontal' : 'vertical'}}" style="min-width: 135px;">     
            <li [ngbNavItem]="tabs[0]" *ngIf="!tabs[0].disabled">
                <a ngbNavLink>{{tabs[0].title}}</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid row g-0">
                        <div class="col-md-2 col-xs-4 col-sm-6">            
                            <app-image class="me-2" width="74px" [imageUrl]="chapter.coverImage"></app-image>
                            ID: {{data.id}}
                        </div>
                        <div class="col-md-10 col-xs-8 col-sm-6">
                            <div class="row g-0">
                                <h4>
                                    {{chapter?.titleName}}
                                </h4>
                                <span>
                                    <span *ngIf="chapterMetadata && chapterMetadata.releaseDate !== null">Release Date: {{chapterMetadata.releaseDate | date: 'shortDate' || '-'}}</span>
                                </span>
                                <span class="text-accent">{{data.pages}} pages</span>
                            </div>
                            <div class="row g-0">
                                <div class="col-auto">
                                    Added: {{(chapter.created | date: 'short') || '-'}}
                                </div>
                            </div>
                            <div class="row g-0">
                                <div class="col-auto">
                                    Age Rating: {{ageRating}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row g-0">
                        <ng-container *ngIf="chapterMetadata !== undefined">
                            <div class="row g-0" *ngIf="chapterMetadata.tags && chapterMetadata.tags.length > 0">
                                <h6>Tags</h6>
                                <app-badge-expander [items]="chapterMetadata.tags">
                                    <ng-template #badgeExpanderItem let-item let-position="idx">
                                        <app-tag-badge>{{item.title}}</app-tag-badge>
                                    </ng-template>  
                                </app-badge-expander>
                            </div>
                            <div class="row g-0" *ngIf="chapterMetadata.genres && chapterMetadata.genres.length > 0">
                                <h6>Genres</h6>
                                <app-badge-expander [items]="chapterMetadata.genres">
                                    <ng-template #badgeExpanderItem let-item let-position="idx">
                                        <app-tag-badge>{{item.title}}</app-tag-badge>
                                    </ng-template>  
                                </app-badge-expander>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>
            </li>

            <li [ngbNavItem]="tabs[1]" *ngIf="!tabs[1].disabled">
                <a ngbNavLink>{{tabs[1].title}}</a>
                <ng-template ngbNavContent>
                    <app-chapter-metadata-detail [chapter]="chapterMetadata"></app-chapter-metadata-detail>
                </ng-template>
            </li>

            <li [ngbNavItem]="tabs[2]" *ngIf="!tabs[2].disabled">
                <a ngbNavLink>{{tabs[2].title}}</a>
                <ng-template ngbNavContent>
                    <app-cover-image-chooser [(imageUrls)]="imageUrls" (imageSelected)="updateSelectedIndex($event)" (selectedBase64Url)="updateSelectedImage($event)" [showReset]="chapter.coverImageLocked" (resetClicked)="handleReset()"></app-cover-image-chooser>
                    <div class="row g-0">
                        <button class="btn btn-primary flex-end mb-2" [disabled]="coverImageSaveLoading" (click)="saveCoverImage()">
                            <ng-container *ngIf="coverImageSaveLoading; else notSaving">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span class="visually-hidden">Loading...</span>
                            </ng-container>
                            <ng-template #notSaving>
                                Save
                            </ng-template>
                        </button>
                    </div>
                </ng-template>
            </li>

            <li [ngbNavItem]="tabs[3]" *ngIf="!tabs[3].disabled">
                <a ngbNavLink>{{tabs[3].title}}</a>
                <ng-template ngbNavContent>
                    <div class="row g-0">
                        <ng-container *ngFor="let bookmark of bookmarks; let idx = index">
                            <app-bookmark [bookmark]="bookmark" class="col-auto" (bookmarkRemoved)="removeBookmark(bookmark, idx)"></app-bookmark>
                        </ng-container>
                        <ng-container *ngIf="bookmarks.length === 0">
                            No bookmarks yet
                        </ng-container>
                    </div>
                </ng-template>
            </li>

            <li [ngbNavItem]="tabs[4]" *ngIf="!tabs[4].disabled">
                <a ngbNavLink>{{tabs[4].title}}</a>
                <ng-template ngbNavContent>
                    <h4 *ngIf="!utilityService.isChapter(data)">{{utilityService.formatChapterName(libraryType) + 's'}}</h4>
                    <ul class="list-unstyled">
                        <li class="d-flex my-4" *ngFor="let chapter of chapters">
                            <a (click)="readChapter(chapter)" href="javascript:void(0);" title="Read {{utilityService.formatChapterName(libraryType, true, false)}} {{formatChapterNumber(chapter)}}">
                                <app-image class="me-2" width="74px" [imageUrl]="chapter.coverImage"></app-image>
                            </a>
                            <div class="flex-grow-1">
                                <h5 class="mt-0 mb-1">
                                    <span >
                                        <span>
                                            <app-card-actionables (actionHandler)="performAction($event, chapter)" [actions]="chapterActions" 
                                            [labelBy]="utilityService.formatChapterName(libraryType, true, true) + formatChapterNumber(chapter)"></app-card-actionables>
                                            <ng-container *ngIf="chapter.number !== '0'; else specialHeader">
                                                {{utilityService.formatChapterName(libraryType, true, false) }} {{formatChapterNumber(chapter)}}
                                            </ng-container>
                                        </span>
                                        <span class="badge bg-primary rounded-pill ms-1">
                                            <span *ngIf="chapter.pagesRead > 0 && chapter.pagesRead < chapter.pages">{{chapter.pagesRead}} / {{chapter.pages}}</span>
                                            <span *ngIf="chapter.pagesRead === 0">UNREAD</span>
                                            <span *ngIf="chapter.pagesRead === chapter.pages">READ</span>
                                        </span>
                                    </span>
                                    <ng-template #specialHeader>Files</ng-template>
                                </h5>
                                <ul class="list-group">
                                    <li *ngFor="let file of chapter.files" class="list-group-item no-hover">
                                        <span>{{file.filePath}}</span>
                                        <div class="row g-0">
                                            <div class="col">
                                                Pages: {{file.pages}}
                                            </div>
                                            <div class="col" *ngIf="data.hasOwnProperty('created')">
                                                Added: {{(data.created | date: 'short') || '-'}}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="tab-content {{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? 'mt-3' : 'ms-4 flex-fill'}}"></div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" (click)="close()">Close</button>
    </div>
</div>

