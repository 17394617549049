<div class="row g-0 mt-2 mb-2">
    <app-read-more [text]="seriesSummary" [maxLength]="250"></app-read-more>
</div>

<!-- This first row will have random information about the series-->
<div class="row g-0 mb-2">
    <app-tag-badge title="Age Rating" *ngIf="seriesMetadata.ageRating" a11y-click="13,32" class="clickable col-auto" (click)="goTo('ageRating', seriesMetadata.ageRating)" [selectionMode]="TagBadgeCursor.Clickable">{{metadataService.getAgeRating(this.seriesMetadata.ageRating) | async}}</app-tag-badge>
    <ng-container *ngIf="series">
        <app-tag-badge *ngIf="seriesMetadata.releaseYear > 0" title="Release date" class="col-auto">{{seriesMetadata.releaseYear}}</app-tag-badge>
        <app-tag-badge *ngIf="seriesMetadata.language !== null && seriesMetadata.language !== ''" title="Language" a11y-click="13,32" class="col-auto" (click)="goTo('languages', seriesMetadata.language)" [selectionMode]="TagBadgeCursor.Clickable">{{seriesMetadata.language}}</app-tag-badge>
        <app-tag-badge title="Publication Status" a11y-click="13,32" class="col-auto" (click)="goTo('publicationStatus', seriesMetadata.publicationStatus)" [selectionMode]="TagBadgeCursor.Clickable">{{seriesMetadata.publicationStatus | publicationStatus}}</app-tag-badge>
        <app-tag-badge a11y-click="13,32" class="col-auto" (click)="goTo('format', series.format)" [selectionMode]="TagBadgeCursor.Clickable">
            <app-series-format [format]="series.format">{{utilityService.mangaFormat(series.format)}}</app-series-format>
        </app-tag-badge>
        <app-tag-badge title="Last Read" class="col-auto" *ngIf="series.latestReadDate && series.latestReadDate !== '' && (series.latestReadDate | date: 'shortDate') !== '1/1/01'"  [selectionMode]="TagBadgeCursor.Selectable">
            Last Read: {{series.latestReadDate | date:'shortDate'}}
        </app-tag-badge>
    </ng-container>
</div>

<div class="row g-0" *ngIf="seriesMetadata.genres && seriesMetadata.genres.length > 0">
    <div class="col-md-4">
        <h5>Genres</h5>
    </div>
    <div class="col-md-8">
        <app-badge-expander [items]="seriesMetadata.genres">
            <ng-template #badgeExpanderItem let-item let-position="idx">
                <app-tag-badge a11y-click="13,32" class="col-auto" (click)="goTo('genres', item.id)" [selectionMode]="TagBadgeCursor.Clickable">{{item.title}}</app-tag-badge>
            </ng-template>  
        </app-badge-expander>
    </div>
</div>
<div class="row g-0 mt-1" *ngIf="seriesMetadata.collectionTags && seriesMetadata.collectionTags.length > 0">
    <div class="col-md-4">
        <h5>Collections</h5>
    </div>
    <div class="col-md-8">
        <app-badge-expander [items]="seriesMetadata.collectionTags">
            <ng-template #badgeExpanderItem let-item let-position="idx">
                <app-tag-badge a11y-click="13,32" class="col-auto" routerLink="/collections/{{item.id}}" [selectionMode]="TagBadgeCursor.Clickable">
                    {{item.title}}
                </app-tag-badge>
            </ng-template>  
        </app-badge-expander>
    </div>
</div>
<div class="row g-0 mt-1" *ngIf="readingLists && readingLists.length > 0">
    <div class="col-md-4">
        <h5>Reading Lists</h5>
    </div>
    <div class="col-md-8">
        <app-badge-expander [items]="readingLists">
            <ng-template #badgeExpanderItem let-item let-position="idx">
                <app-tag-badge a11y-click="13,32" class="col-auto" routerLink="/lists/{{item.id}}" [selectionMode]="TagBadgeCursor.Clickable">
                    <!-- TODO: Build a promoted badge code -->
                    <span *ngIf="item.promoted">
                        <i class="fa fa-angle-double-up" aria-hidden="true"></i>&nbsp;
                        <span class="visually-hidden">(promoted)</span>
                    </span>
                    {{item.title}}
                </app-tag-badge>
            </ng-template>  
        </app-badge-expander>
    </div>
</div>
<div class="row g-0 mt-1" *ngIf="seriesMetadata.writers && seriesMetadata.writers.length > 0">
    <div class="col-md-4">
        <h5>Writers/Authors</h5>
    </div>
    <div class="col-md-8">
        <app-badge-expander [items]="seriesMetadata.writers">
            <ng-template #badgeExpanderItem let-item let-position="idx">
                <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('writers', item.id)" [person]="item"></app-person-badge>
            </ng-template>  
        </app-badge-expander>
    </div>
</div>

<div class="row g-0">
    <hr class="col mt-3" *ngIf="hasExtendedProperites" >
    <a [class.hidden]="hasExtendedProperites" *ngIf="hasExtendedProperites" class="col col-md-auto align-self-end read-more-link" (click)="toggleView()">&nbsp;<i aria-hidden="true" class="fa fa-caret-{{isCollapsed ? 'down' : 'up'}}" aria-controls="extended-series-metadata"></i>&nbsp;See {{isCollapsed ? 'More' : 'Less'}}</a>
</div>

<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" id="extended-series-metadata">
    <div class="row g-0 mt-1"  *ngIf="seriesMetadata.coverArtists && seriesMetadata.coverArtists.length > 0">
        <div class="col-md-4">
            <h5>Cover Artists</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.coverArtists">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('coverArtists', item.id)" [person]="item"></app-person-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>
    
    <div class="row g-0 mt-1"  *ngIf="seriesMetadata.characters && seriesMetadata.characters.length > 0">
        <div class="col-md-4">
            <h5>Characters</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.characters">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('character', item.id)" [person]="item"></app-person-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>

    <div class="row g-0 mt-1"  *ngIf="seriesMetadata.colorists && seriesMetadata.colorists.length > 0">
        <div class="col-md-4">
            <h5>Colorists</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.colorists">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('colorist', item.id)" [person]="item"></app-person-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>

    <div class="row g-0 mt-1"  *ngIf="seriesMetadata.editors && seriesMetadata.editors.length > 0">
        <div class="col-md-4">
            <h5>Editors</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.editors">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('editor', item.id)" [person]="item"></app-person-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>

    <div class="row g-0 mt-1"  *ngIf="seriesMetadata.inkers && seriesMetadata.inkers.length > 0">
        <div class="col-md-4">
            <h5>Inkers</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.inkers">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('inker', item.id)" [person]="item"></app-person-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>

    <div class="row g-0 mt-1"  *ngIf="seriesMetadata.letterers && seriesMetadata.letterers.length > 0">
        <div class="col-md-4">
            <h5>Letterers</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.letterers">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('letterer', item.id)" [person]="item"></app-person-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>
    <div class="row g-0" *ngIf="seriesMetadata.tags && seriesMetadata.tags.length > 0">
        <div class="col-md-4">
            <h5>Tags</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.tags">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-tag-badge a11y-click="13,32" class="col-auto" (click)="goTo('tags', item.id)" [selectionMode]="TagBadgeCursor.Clickable">{{item.title}}</app-tag-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>
    <div class="row g-0 mt-1"  *ngIf="seriesMetadata.translators && seriesMetadata.translators.length > 0">
        <div class="col-md-4">
            <h5>Translators</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.translators">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('translators', item.id)" [person]="item"></app-person-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>
    
    <div class="row g-0 mt-1"  *ngIf="seriesMetadata.pencillers && seriesMetadata.pencillers.length > 0">
        <div class="col-md-4">
            <h5>Pencillers</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.pencillers">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('penciller', item.id)" [person]="item"></app-person-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>

    <div class="row g-0 mt-1"  *ngIf="seriesMetadata.publishers && seriesMetadata.publishers.length > 0">
        <div class="col-md-4">
            <h5>Publishers</h5>
        </div>
        <div class="col-md-8">
            <app-badge-expander [items]="seriesMetadata.publishers">
                <ng-template #badgeExpanderItem let-item let-position="idx">
                    <app-person-badge a11y-click="13,32" class="col-auto" (click)="goTo('publisher', item.id)" [person]="item"></app-person-badge>
                </ng-template>  
            </app-badge-expander>
        </div>
    </div>
</div>