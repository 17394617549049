<app-splash-container>
    <ng-container title><h2>Password Reset</h2></ng-container>
    <ng-container body>
        <p>Enter the email of your account. We will send you an email </p>
        <form [formGroup]="registerForm" (ngSubmit)="submit()">
            <div class="mb-3" style="width:100%">
                <label for="email" class="form-label">Email</label>
                <input class="form-control custom-input" type="email" id="email" formControlName="email" required>
                <div id="inviteForm-validations" class="invalid-feedback" *ngIf="registerForm.dirty || registerForm.touched">
                    <div *ngIf="registerForm.get('email')?.errors?.required">
                        This field is required
                    </div>
                    <div *ngIf="registerForm.get('email')?.errors?.email">
                        This must be a valid email address
                    </div>
                </div>
            </div>
        
            <div>
                <button class="btn btn-secondary alt" type="submit">Submit</button>
            </div>
        </form>
    </ng-container>
</app-splash-container>
