<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Account Migration</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()">
    
    </button>
</div>
<div class="modal-body">
    <p>Your account does not have an email on file. This is a one-time migration. Please add your email to the account. A verficiation link will be sent to your email for you 
        to confirm and will then be allowed to authenticate with this server. This is required.
    </p>

    <p class="text-danger" *ngIf="error.length > 0">{{error}}</p>

    <form [formGroup]="registerForm">
        <div class="mb-3">
            <label for="username" class="form-label">Username</label>
            <input id="username" class="form-control" formControlName="username" type="text">
            <div id="inviteForm-validations" class="invalid-feedback" *ngIf="registerForm.dirty || registerForm.touched">
                <div *ngIf="registerForm.get('username')?.errors?.required">
                    This field is required
                </div>
            </div>
        </div>

        <div class="mb-3" style="width:100%">
            <label for="email" class="form-label">Email</label>
            <input class="form-control" type="email" id="email" formControlName="email" required>
            <div id="inviteForm-validations" class="invalid-feedback" *ngIf="registerForm.dirty || registerForm.touched">
                <div *ngIf="registerForm.get('email')?.errors?.required">
                    This field is required
                </div>
                <div *ngIf="registerForm.get('email')?.errors?.email">
                    This must be a valid email address
                </div>
            </div>
        </div>
        
        <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <input id="password" class="form-control" maxlength="32" minlength="6" formControlName="password" type="password" aria-describedby="password-help">
            <div id="inviteForm-validations" class="invalid-feedback" *ngIf="registerForm.dirty || registerForm.touched">
                <div *ngIf="registerForm.get('password')?.errors?.required">
                    This field is required
                </div>
            </div>
        </div>
    </form>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">
        Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="isSaving || !registerForm.valid">
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span>Submit</span>
    </button>
</div>
