<div
  class="drawer-container"
  [ngStyle]="{'top': options.topOffset + 'px', 'padding-bottom': options.topOffset + 'px'}"
  [class.is-open]="isOpen"
  [class.position-right]="position === 'right'"
  [class.position-left]="position === 'left'"
  [class.position-bottom]="position === 'bottom'"
>
  <div class="header">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="body">
    <ng-content select="[body]"></ng-content>
  </div>
</div>