<div class="container-fluid" style="padding-left: 0px; padding-right: 0px">
    <form [formGroup]="form">
        <ngx-file-drop (onFileDrop)="dropped($event)" 
        (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" accept=".png,.jpg,.jpeg" [directory]="false" dropZoneClassName="file-upload" contentClassName="file-upload-zone" [directory]="false">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                <div class="row g-0 mt-3 pb-3" *ngIf="mode === 'all'">
                    <div class="mx-auto">
                        <div class="row g-0 mb-3">
                            <i class="fa fa-file-upload mx-auto" style="font-size: 24px; width: 20px;" aria-hidden="true"></i>
                        </div>
                        
                        <div class="row g-0">
                            <div class="mx-auto" style="width: 350px;">
                                <a class="col" style="padding-right:0px" href="javascript:void(0)" (click)="changeMode('url')"><span class="phone-hidden">Enter a </span>Url</a>
                                <span class="col ps-1 pe-1">•</span>
                                <span class="col" style="padding-right:0px" href="javascript:void(0)">Drag and drop</span>
                                <span class="col ps-1 pe-1" style="padding-right:0px">•</span>
                                <a class="col" style="padding-right:0px" href="javascript:void(0)" (click)="openFileSelector()">Upload<span class="phone-hidden"> an image</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                

                <ng-container *ngIf="mode === 'url'">
                    <div class="row g-0 mt-3 pb-3 ms-md-2 me-md-2">
                        <div class="input-group col-auto me-md-2" style="width: 83%">
                            <label class="input-group-text" for="load-image">Url</label>
                            <input type="text" autofocus autocomplete="off" class="form-control" formControlName="coverImageUrl" placeholder="https://" id="load-image" class="form-control">
                            <button class="btn btn-outline-secondary" type="button" id="load-image-addon" (click)="loadImage(); mode='all';" [disabled]="form.get('coverImageUrl')?.value.length === 0">
                                Load
                            </button>
                        </div>
                        <button class="btn btn-secondary col-auto" href="javascript:void(0)" (click)="mode = 'all'" aria-label="Back">
                            <i class="fas fa-share" aria-hidden="true" style="transform: rotateY(180deg)"></i>&nbsp;
                            <span class="phone-hidden">Back</span>
                        </button>
                    </div>
                    
                </ng-container>
                
            </ng-template>
        </ngx-file-drop>

        <ng-template>

        </ng-template>
    </form>

    <div class="row g-0 chooser" style="padding-top: 10px">
        <div class="image-card col-auto {{selectedIndex === idx ? 'selected' : ''}}" *ngFor="let url of imageUrls; let idx = index;" tabindex="0" attr.aria-label="Image {{idx + 1}}" (click)="selectImage(idx)">
            <app-image class="card-img-top" height="230px" width="158px" [imageUrl]="url"></app-image>
        </div>
        <div class="image-card col-auto {{selectedIndex === -1 ? 'selected' : ''}}" *ngIf="showReset" tabindex="0" attr.aria-label="Reset cover image" (click)="reset()">
            <app-image class="card-img-top" title="Reset Cover Image" height="230px" width="158px" [imageUrl]="imageService.resetCoverImage"></app-image>
        </div>
    </div>

</div>

