<div *ngIf="series !== undefined">
    <div class="modal-header">
        <h4 class="modal-title">
            {{this.series.name}} Details</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="close()">
            
        </button>
    </div>
    <div class="modal-body scrollable-modal {{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? '' : 'd-flex'}}">
        <form [formGroup]="editSeriesForm">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="{{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? 'horizontal' : 'vertical'}}" style="min-width: 135px;">
                
                    <li [ngbNavItem]="tabs[0]">
                    <a ngbNavLink>{{tabs[0]}}</a>
                    <ng-template ngbNavContent>
                        <div class="row g-0">
                            <div class="mb-3" style="width: 100%">
                                <label for="name" class="form-label">Name</label>
                                <div class="input-group {{series.nameLocked ? 'lock-active' : ''}}">
                                    <ng-container [ngTemplateOutlet]="lock" [ngTemplateOutletContext]="{ item: series, field: 'nameLocked' }"></ng-container>
                                    <input id="name" class="form-control" formControlName="name" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="row g-0">
                            <div class="mb-3" style="width: 100%">
                                <label for="sort-name" class="form-label">Sort Name</label>
                                <div class="input-group {{series.sortNameLocked ? 'lock-active' : ''}}">
                                    <ng-container [ngTemplateOutlet]="lock" [ngTemplateOutletContext]="{ item: series, field: 'sortNameLocked' }"></ng-container>
                                    <input id="sort-name" class="form-control" formControlName="sortName" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="row g-0">
                            <div class="mb-3" style="width: 100%">
                                <label for="localized-name" class="form-label">Localized Name</label>
                                <div class="input-group {{series.localizedNameLocked ? 'lock-active' : ''}}">
                                    <ng-container [ngTemplateOutlet]="lock" [ngTemplateOutletContext]="{ item: series, field: 'localizedNameLocked' }"></ng-container>
                                    <input id="localized-name" class="form-control" formControlName="localizedName" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="row g-0" *ngIf="metadata">
                            <div class="mb-3" style="width: 100%">
                                <label for="summary" class="form-label">Summary</label>
                                <div class="input-group {{metadata?.summaryLocked ? 'lock-active' : ''}}">
                                    <ng-container [ngTemplateOutlet]="lock" [ngTemplateOutletContext]="{ item: metadata, field: 'summaryLocked' }"></ng-container>
                                    <textarea id="summary" class="form-control" formControlName="summary" rows="4"></textarea>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                    </li>
                    <li [ngbNavItem]="tabs[1]" *ngIf="metadata">
                        <a ngbNavLink>{{tabs[1]}}</a>
                        <ng-template ngbNavContent>

                            <div class="row g-0">
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="collections" class="form-label">Collections </label>
                                        <app-typeahead (selectedData)="updateCollections($event)" [settings]="collectionTagSettings" [locked]="true">
                                            <ng-template #badgeItem let-item let-position="idx">
                                                {{item.title}}
                                            </ng-template>
                                            <ng-template #optionItem let-item let-position="idx">
                                                {{item.title}}
                                            </ng-template>
                                        </app-typeahead>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row g-0">
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="genres" class="form-label">Genres</label>
                                        <app-typeahead (selectedData)="updateGenres($event)" [settings]="genreSettings" 
                                            [(locked)]="metadata.genresLocked" (onUnlock)="metadata.genresLocked = false"
                                            (newItemAdded)="metadata.genresLocked = true" (selectedData)="metadata.genresLocked = true">
                                            <ng-template #badgeItem let-item let-position="idx">
                                                {{item.title}}
                                            </ng-template>
                                            <ng-template #optionItem let-item let-position="idx">
                                                {{item.title}}
                                            </ng-template>
                                        </app-typeahead>
                                    </div>
                                </div>
                            </div>

                            <div class="row g-0">
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="tags" class="form-label">Tags</label>
                                        <app-typeahead (selectedData)="updateTags($event)" [settings]="tagsSettings"
                                            [(locked)]="metadata.tagsLocked" (onUnlock)="metadata.tagsLocked = false"
                                            (newItemAdded)="metadata.tagsLocked = true" (selectedData)="metadata.tagsLocked = true">
                                            <ng-template #badgeItem let-item let-position="idx">
                                                {{item.title}}
                                            </ng-template>
                                            <ng-template #optionItem let-item let-position="idx">
                                                {{item.title}}
                                            </ng-template>
                                        </app-typeahead>
                                    </div>
                                </div>
                            </div>

                            <div class="row g-0">
                                <div class="col-lg-4 col-md-12 pe-2">
                                    <div class="mb-3">
                                        <label for="language" class="form-label">Language</label>
                                        <app-typeahead (selectedData)="updateLanguage($event)" [settings]="languageSettings"
                                            [(locked)]="metadata.languageLocked" (onUnlock)="metadata.languageLocked = false"
                                            (newItemAdded)="metadata.languageLocked = true" (selectedData)="metadata.languageLocked = true">
                                            <ng-template #badgeItem let-item let-position="idx">
                                                {{item.title}}
                                            </ng-template>
                                            <ng-template #optionItem let-item let-position="idx">
                                                {{item.title}} ({{item.isoCode}})
                                            </ng-template>
                                        </app-typeahead>
                                    </div>
                                </div>
                                <div class="col-lg-4  col-md-12 pe-2">
                                    <div class="mb-3">
                                        <label for="age-rating" class="form-label">Age Rating</label>
                                        <div class="input-group {{metadata.ageRatingLocked ? 'lock-active' : ''}}">
                                            <ng-container [ngTemplateOutlet]="lock" [ngTemplateOutletContext]="{ item: metadata, field: 'ageRatingLocked' }"></ng-container>
                                            <select class="form-select"id="age-rating" formControlName="ageRating">
                                                <option *ngFor="let opt of ageRatings" [value]="opt.value">{{opt.title | titlecase}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="mb-3">
                                        <label for="publication-status" class="form-label">Publication Status</label>
                                        <div class="input-group {{metadata.publicationStatusLocked ? 'lock-active' : ''}}">
                                            <ng-container [ngTemplateOutlet]="lock" [ngTemplateOutletContext]="{ item: metadata, field: 'publicationStatusLocked' }"></ng-container>
                                            <select class="form-select"id="publication-status" formControlName="publicationStatus">
                                                <option *ngFor="let opt of publicationStatuses" [value]="opt.value">{{opt.title | titlecase}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                
                    <li [ngbNavItem]="tabs[2]">
                        <a ngbNavLink>{{tabs[2]}}</a>
                        <ng-template ngbNavContent>
                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="writer" class="form-label">Writer</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.Writer)" [settings]="getPersonsSettings(PersonRole.Writer)"
                                        [(locked)]="metadata.writersLocked" (onUnlock)="metadata.writersLocked = false"
                                        (newItemAdded)="metadata.writersLocked = true" (selectedData)="metadata.writersLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>
                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="cover-artist" class="form-label">Cover Artist</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.CoverArtist)" [settings]="getPersonsSettings(PersonRole.CoverArtist)"
                                        [(locked)]="metadata.coverArtistsLocked" (onUnlock)="metadata.coverArtistsLocked = false"
                                        (newItemAdded)="metadata.coverArtistsLocked = true" (selectedData)="metadata.coverArtistsLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>


                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="publisher" class="form-label">Publisher</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.Publisher)" [settings]="getPersonsSettings(PersonRole.Publisher)"
                                        [(locked)]="metadata.publishersLocked" (onUnlock)="metadata.publishersLocked = false"
                                        (newItemAdded)="metadata.publishersLocked = true" (selectedData)="metadata.publishersLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>
                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="penciller" class="form-label">Penciller</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.Penciller)" [settings]="getPersonsSettings(PersonRole.Penciller)"
                                        [(locked)]="metadata.pencillersLocked" (onUnlock)="metadata.pencillersLocked = false"
                                        (newItemAdded)="metadata.pencillersLocked = true" (selectedData)="metadata.pencillersLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>


                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="letterer" class="form-label">Letterer</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.Letterer)" [settings]="getPersonsSettings(PersonRole.Letterer)"
                                        [(locked)]="metadata.letterersLocked" (onUnlock)="metadata.letterersLocked = false"
                                        (newItemAdded)="metadata.letterersLocked = true" (selectedData)="metadata.letterersLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>
                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="inker" class="form-label">Inker</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.Inker)" [settings]="getPersonsSettings(PersonRole.Inker)"
                                        [(locked)]="metadata.inkersLocked" (onUnlock)="metadata.inkersLocked = false"
                                        (newItemAdded)="metadata.inkersLocked = true" (selectedData)="metadata.inkersLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>



                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="editor" class="form-label">Editor</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.Editor)" [settings]="getPersonsSettings(PersonRole.Editor)"
                                        [(locked)]="metadata.editorsLocked" (onUnlock)="metadata.editorsLocked = false"
                                        (newItemAdded)="metadata.editorsLocked = true" (selectedData)="metadata.editorsLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>
                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="colorist" class="form-label">Colorist</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.Colorist)" [settings]="getPersonsSettings(PersonRole.Colorist)"
                                        [(locked)]="metadata.coloristsLocked" (onUnlock)="metadata.coloristsLocked = false"
                                        (newItemAdded)="metadata.coloristsLocked = true" (selectedData)="metadata.coloristsLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>

                        

                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="character" class="form-label">Character</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.Character)" [settings]="getPersonsSettings(PersonRole.Character)"
                                        [(locked)]="metadata.charactersLocked" (onUnlock)="metadata.charactersLocked = false"
                                        (newItemAdded)="metadata.charactersLocked = true" (selectedData)="metadata.charactersLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>
                            <div class="row g-0">
                                <div class="mb-3">
                                    <label for="translator" class="form-label">Translators</label>
                                    <app-typeahead (selectedData)="updatePerson($event, PersonRole.Translator)" [settings]="getPersonsSettings(PersonRole.Translator)"
                                        [(locked)]="metadata.translatorsLocked" (onUnlock)="metadata.translatorsLocked = false"
                                        (newItemAdded)="metadata.translatorsLocked = true" (selectedData)="metadata.translatorsLocked = true">
                                        <ng-template #badgeItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                        <ng-template #optionItem let-item let-position="idx">
                                            {{item.name}}
                                        </ng-template>
                                    </app-typeahead>
                                </div>
                            </div>

                        </ng-template>
                    </li>
                
                <li [ngbNavItem]="tabs[3]">
                  <a ngbNavLink>{{tabs[3]}}</a>
                  <ng-template ngbNavContent>
                    <p class="alert alert-primary" role="alert">
                        Upload and choose a new cover image. Press Save to upload and override the cover.
                    </p>
                    <app-cover-image-chooser [(imageUrls)]="imageUrls" (imageSelected)="updateSelectedIndex($event)" (selectedBase64Url)="updateSelectedImage($event)" [showReset]="series.coverImageLocked" (resetClicked)="handleReset()"></app-cover-image-chooser>
                  </ng-template>
                </li>
                <li [ngbNavItem]="tabs[4]">
                  <a ngbNavLink>{{tabs[4]}}</a>
                  <ng-template ngbNavContent>
                    <h4>Information</h4>
                    <div class="row g-0 mb-2">
                        <div class="col-md-6" *ngIf="libraryName">Library: {{libraryName | sentenceCase}}</div>
                        <div class="col-md-6">Format: <app-tag-badge>{{utilityService.mangaFormat(series.format)}}</app-tag-badge></div>
                    </div>
                      <h4>Volumes</h4>
                      <div class="spinner-border text-secondary" role="status" *ngIf="isLoadingVolumes">
                          <span class="invisible">Loading...</span>
                      </div>
                      <ul class="list-unstyled" *ngIf="!isLoadingVolumes">
                          <li class="d-flex my-4" *ngFor="let volume of seriesVolumes">
                              <app-image class="me-3" style="width: 74px;" width="74px" [imageUrl]="imageService.getVolumeCoverImage(volume.id)"></app-image>
                              <div class="flex-grow-1">
                                  <h5 class="mt-0 mb-1">Volume {{volume.name}}</h5>
                                  <div>
                                      <div class="row g-0">
                                          <div class="col">
                                              Added: {{volume.created | date: 'short'}}
                                          </div>
                                          <div class="col">
                                              Last Modified: {{volume.lastModified | date: 'short'}}
                                          </div>
                                      </div>
                                      <div class="row g-0">
                                          <div class="col">
                                              <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()" [attr.aria-expanded]="!volumeCollapsed[volume.name]">
                                                  View Files
                                              </button>
                                          </div>
                                          <div class="col">
                                              Pages: {{volume.pages}}
                                          </div>
                                      </div>
                                      
                                      <div #collapse="ngbCollapse" [(ngbCollapse)]="volumeCollapsed[volume.name]">
                                          <ul class="list-group mt-2">
                                              <li *ngFor="let file of volume.volumeFiles.sort()" class="list-group-item">
                                                  <span>{{file.filePath}}</span>
                                                  <div class="row g-0">
                                                      <div class="col">
                                                          Chapter: {{file.chapter}}
                                                      </div>
                                                      <div class="col">
                                                          Pages: {{file.pages}}
                                                      </div>
                                                      <div class="col">
                                                          Format: <span class="badge badge-secondary">{{utilityService.mangaFormatToText(file.format)}}</span>
                                                      </div>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </li>
                      </ul>
                  </ng-template>
                </li>
            </ul>
        </form>
        
        <div [ngbNavOutlet]="nav" class="tab-content {{utilityService.getActiveBreakpoint() === Breakpoint.Mobile ? 'mt-3' : 'ms-4 flex-fill'}}"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
        <button type="submit" class="btn btn-primary" (click)="save()">Save</button>
    </div>
</div>


<ng-template #lock let-item="item" let-field="field">
    <span class="input-group-text clickable" (click)="unlock(item, field)">
        <i class="fa fa-lock" aria-hidden="true"></i>
        <span class="visually-hidden">Field is locked</span>
    </span>
</ng-template>

