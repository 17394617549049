<div class="card" *ngIf="bookmark != undefined">
    <app-image height="230px" width="170px" [imageUrl]="imageService.getBookmarkedImage(bookmark.chapterId, bookmark.page)"></app-image>        

    <div class="card-body" *ngIf="bookmark.page >= 0">
      <div class="header-row">
        <span class="card-title" tabindex="0">
            Page {{bookmark.page + 1}}
        </span>
        <span class="card-actions float-end" *ngIf="series != undefined">
            <button attr.aria-labelledby="series--{{series.name}}" class="btn btn-danger btn-sm" (click)="removeBookmark()" 
            [disabled]="isClearing" placement="top" ngbTooltip="Remove Bookmark" attr.aria-label="Remove Bookmark">
                <ng-container *ngIf="isClearing; else notClearing">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="visually-hidden">Loading...</span>
                </ng-container>
                <ng-template #notClearing>
                    <i class="fa fa-trash-alt" aria-hidden="true"></i>
                </ng-template>
            </button>
        </span>
      </div>
      <div>
        <a *ngIf="series != undefined" class="title-overflow library" href="/library/{{series.libraryId}}/series/{{series.id}}" 
        placement="top" id="bookmark_card_{{series.name}}_{{bookmark.id}}" [ngbTooltip]="series.name | titlecase">{{series.name | titlecase}}</a>
      </div>
    </div>
</div>